
button, input, optgroup, select, textarea, .MuiInputBase-root, .MuiFormLabel-root .MuiTypography-body1{
  font-family: var(--poppins) !important;
}
/*=====================LeadFlow table Css========================*/
.lead_flow_top_content {
    padding: 15px 20px !important;
    justify-content: space-between;
}
.lead_flow_top_content h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #133159;
    margin: 0;
}
.create_new_leadflow_btn a{
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 14px 19px;
    border: unset !important;
}
.non_active_btn a{
    background: #ADB7C5;
}
.btn_icon{
    margin-right: 10px;
    height: 22px;
}
.lead_flow_list{
    display: flex;
    /* justify-content: space-between; */
    column-gap: 10px;
    background: white;
    margin: 20px 0px;
    align-items: center;
    padding: 36px 20px;
    border-radius: 10px;
}
.list__title{
    width: 15%;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--dark_blue);
    margin-right: 10px;
}
.calendar strong{
  color: rgba(60, 126, 243, 1) !important;
}
.list__title .calendar{
    color: var(--marketing-btn-bg);
}
.list__title span{
    display: flex;
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
    color: var(--text-color);
}
.list__title ul{
    margin: 0;
}
.list__title ul li{
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: var(--text-color);
}
.list__title span a{
    color: var(--text-color);
    margin-left: 5px;
    margin-right: 5px;
}
.btn__wrapper{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.btn__wrapper .btn_text{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--dark_blue);
  margin-right: 12px;
  text-transform: capitalize;
  background: #F6F7FB;
  border-radius: 5px;
  /* padding: 10px 12px; */
}
.btn__wrapper a{
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--dark_blue);
  margin-right: 12px;
  text-transform: capitalize;
  background: #F6F7FB;
  border-radius: 5px;
  padding: 10px 12px;
}
.btn__wrapper button{
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--dark_blue);
    margin-right: 12px;
    text-transform: capitalize;
    cursor: pointer;
    border: unset !important;
    background: #F6F7FB;
    border-radius: 5px;
    padding: 10px 12px;
}
.btn__wrapper button:last-child{
    margin-right: unset !important;
}
.button_icon{
    margin-right: 10px;
    height: 20px;
}
.btn__wrapper a:last-child{
    margin-right: 0px;
}
.btn__wrapper .delete_btn{
    color: var(--red) !important;
}
.btn__wrapper a span{
    /* margin-right: 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn__wrapper .settings-btn{
    border: 1px solid #E5E8EF;
    padding: 10px 15px;
    margin-right: 15px;
    background: var(--gray_light);
    border-radius: 5px;
}
.btn__wrapper .zapier-btn{
  border-radius: 5px;
  color: var(--white);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}
.btn__wrapper .src_btn{
    padding: 10px 41px;
}
.list__subtitle{
    width: 20%;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
    margin-right: 10px;
    text-align: center;
    /* margin-left: auto; */
}
.list__subtitle img{
    width: 220px;
    height: 50px;
}
.btn__wrapper.alt{
    margin-right: 10px;
    width: 25%;
    display: flex;
    justify-content: center;
}
.create_new_lead_flow_select{
    margin-left: 50px;
    max-width: 307px;
    width: 100%;
    margin-top: -60px;
}
.create_new_lead_flow_select a {
    background: #FFFFFF;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 37px 10px;
}
.create_new_lead_flow_select a span{
    margin-right: 10px;
}

/* ===========Modal css============== */
.add_leadflow_modal{
    left: unset;
    top: 81px !important;
    background: #F6F7FB;
    border-radius: 10px 0px 0px 10px;
    max-width: 820px;
    width: 100%;
    position: fixed;
    right: 0;
    padding: 0;
    max-height: 70%;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
    will-change: top, opacity;
}
.add_leadflow_modal:focus {
    outline: none;
}
.add_leadflow_modal .contact_source_container{
    min-height: 200px;
}

.top_content {
    border-bottom: 1px solid #E7EFF0;
}
.sub_title h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
}
.cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}
.modal__buttons{
    padding: 30px;
    border-top: 1px solid #E7EFF0;
}
.add__group__btn, .modal__close__btn{
    font-size: 14px;
    line-height: 30px;
    height: 50px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #2F80ED;
    border: none;
    cursor: pointer;
}
.add__group__btn:focus, .modal__close__btn:focus{
  /*background-color: #2F80ED !important;*/
}
.modal__close__btn {
    background-color: var(--red);
    margin-left: 20px;
}
.input_field_group{
  padding: 20px 10px 0px 15px;
}
.lead_src_select input, 
.title_input_field input{
    background: #FFFFFF  !important;
    border: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 24px 20px !important;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}
.title_input_field input:focus{
    border-bottom: 1px solid rgba(19, 49, 89, 0.35) !important; 
    box-shadow: unset !important;
}
.lead_src_select label,
.title_input_field label{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.lead_src_select .MuiInput-underline:before{
  display: none !important;
}
.lead_src_select .MuiInput-underline:after{
  display: none !important;
}
.lead_src_select .MuiInputBase-root{
  display: block !important;
}
.lead_src_select .MuiSelect-select{
  background: #FFFFFF !important;
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 13px 20px !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.35);
}


.lead_src_select .check_icon,
.title_input_field span{
    color: red !important;
}
.lead_src_select svg{
  z-index: 1 !important;
  fill: rgba(84, 99, 118, 1)!important;
}
/* ================LeadFlow__setting__css================ */
.rule_top{
    border: 1px solid #E5E8EF;
    padding: 10px 20px;
}
.title_icon{
  height: 20px;
}
.title{
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    display: flex;
    align-items: center;
    gap: 5px;
}
.delete_btn button{
    border: unset;
    cursor: pointer;
    background: #F6F7FB;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF264A;
    padding: 8px 21.5px;
}
.delete_btn_icon{
    margin-right: 10px;
}
.top_lead_setting_btn{
    /* gap: 20px; */
}
.create_new_leadflow_btn{
  margin-right: 15px;
}
.create_new_leadflow_btn:last-child{
  margin-right: unset;
}
.add_rule_btn a{
    background: #F6F7FB;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #3C7EF3;
    font-weight: normal;
    padding: 5px 30px;
    display: flex;
    align-items: center;
}
.add_rule_top_content{
    border-bottom: 1px solid #E5E8EF;
    padding: 20px;
}
.add_rule_content{
    background: white;
    margin: 20px 0px;
    border-radius: 10px;
}
.assign_content{
    padding: 20px;
}
.assign_to{
  margin-right: 50px;
}
.assing_btns a,
.assing_btns button{
    background: #3C7EF3;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    border: unset !important;
    cursor: pointer;
    padding: 8px 14px !important;
}
.assing_btns_text{
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    margin-right: 15px;
}
.drp_select{
    margin: 0px 14px;
}
.rule_input_content{
    padding: 20px;
}
.rule_inputs{
  align-items: center;
}

.drp_select input,
.rule_inputs input{
    background: #E5E8EF !important;
    border: 1px solid #ADB7C5 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 15px !important;
    margin-top: 18px !important;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}
.drp_select input:focus,
.rule_inputs input:focus {
    border-bottom: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-shadow: unset !important;
}
.drp_select input{
    width: 88px !important;
}
.drp_select svg,
.rule_inputs svg{
    z-index: 1 !important;
    fill: rgba(19, 49, 89, 0.65) !important;
    cursor: pointer;
    right: 8px !important;
}
.rule_input_search{
    position: relative;
}
.search_icon{
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}
/*  */
/***********************
Group Lists Modal CSS
************************/
#addToGroup {
    background-color: var(--white);
    top: 80px !important;
    left: unset;
    max-width: 1170px;
    border-radius: 10px 0 0 10px;
    max-height: 88%;
  }
  .add__group__btn:focus-within {
    background-color: var(--blue-1);
  }
  .modal-content {
    padding: unset !important;
  }
  .modal__content__top h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark_blue);
    border-bottom: 1px solid #efebff;
    padding-bottom: 17px;
  }
  .modal__input__box label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: block;
    color: var(--dark_blue);
    margin-bottom: 8px;
  }
  .modal__input {
    background-color: var(--gray_light) !important;
    border-bottom: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 0px 17px !important;
    margin-bottom: 20px !important;
    box-sizing: border-box !important;
    border: 1px solid #E5E8EF !important;
    font-size: 16px !important;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #BABABA;    
    height: 40px !important;
  }
  .modal__input__search {
    background-color: var(--white) !important;
    border-bottom: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 0px 17px !important;
    box-sizing: border-box !important;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ADB7C5;
  }
  .modal__input::placeholder {
    color: var(--gray);
    opacity: 1; 
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  
  .modal__input:-ms-input-placeholder {
    color: var(--gray);
  }
  
  .modal__input ::-ms-input-placeholder {
    color: var(--gray);
  }
  .modal__input__search::placeholder {
    color: var(--gray);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  
  .modal__input__search:-ms-input-placeholder {
    color: var(--gray);
  }
  
  .modal__input__search ::-ms-input-placeholder {
    color: var(--gray);
  }
  .modal__search__box {
    background-color: #F6F7FB;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #E5E8EF;
    position: relative;
  }
  .modal__search__box  span.serach_icon{
    position: absolute;
    right: 33px;
    top: 31px;
    cursor: pointer;
  }
  .modal__search__preview {
    height: 222px;
    padding: 20px;
    background: white;
    border-radius: 5px;
  }
  .modal__search__preview.awesome__scroll_bar{
    padding-bottom: unset;
  }
  .modal__search__preview.awesome__scroll_bar::-webkit-scrollbar-track{
    background-color: rgba(60, 126, 243, 0.1);
  }
  .modal__search__preview.awesome__scroll_bar::-webkit-scrollbar-thumb{
    background-color: var(--dark_blue);
  }
  [type="checkbox"].filled-in:checked+span:not(.lever):after{
    background-color: var(--light_blue) !important;
  }
  .modal__search__preview .checkbox__text_1::after {
    border: 1px solid var(--light_blue) !important;
  }
  .modal__search__preview label {
    background-color: #F6F7FB;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 16px;
    margin: 10px;
  }
  .modal__search__preview label:hover{
    background-color: rgba(60, 126, 243, 0.1);
  }
  /* .modal__search__input:focus + .modal__search__preview {
    display: block !important;
  } */
  .modal__checkbox {
    background-color: var(--gray_light);
    padding: 20px;
    margin-right: 10px;
    height: 93px;
    margin-top: 20px;
  }
  .modal__checkbox label {
    display: flex;
  }
  .checkbox__text__holder {
    display: flex;
    flex-direction: column;
  }
  
  .checkbox__text__holder::after {
    border: 1px solid var(--light_blue) !important;
  }
  .checkbox__text_1 {
    font-weight: 500;
    font-size: 16px !important;
    color: rgba(19, 49, 89, 0.65);
    line-height: 22px !important;
    letter-spacing: 0.01em;
  }
  .checkbox__text_2,
  .modal__select__text {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #99a6b9;
    line-height: 22px;
    margin-right: 20px;
    letter-spacing: 0.01em;
  }
  .checkbox__text__holder span {
    display: block;
  }
  .selector__box {
    margin-left: 20px;
    background-color: var(--gray);
    border-radius: 10px;
  }
  .selector__box .select-wrapper {
    padding: 5px 10px !important;
    border-bottom: none !important;
  }
  .select-wrapper .caret {
    fill: rgba(19, 49, 89, 0.65) !important;
    font-size: 16px;
  }
  .selector__box .select-wrapper input {
    background-color: transparent !important;
    border-bottom: none !important;
    color: var(--white);
    font-size: 16px;
    height: 2rem;
    max-width: 140px;
  }
  .modal-footer {
    height: 70px !important;
    padding: 0 20px !important;
    margin-bottom: 20px;
  }
  .modal__buttons {
    padding-top: 20px;
    border-top: 1px solid #efebff;
  }
  .modal__close__btn {
    background-color: var(--red);
    margin-left: 20px;
  }
  .modal__close__btn:focus-within {
    background-color: var(--red);
  }
  
  
  .add_grp_select input{
    border-bottom: unset !important;
    background: #546376 !important;
    color: white;
    border-radius: 5px !important;
    height: 30px !important;
    width: 120px !important;
    padding: 0px 10px !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em;
  }
  .add_grp_select .caret{
    display: none;
  }
  .add_grp_select .select_icon{
    position: absolute;
    right: 12px;
    top: 3px;
    z-index: 1;
  }
  .group__delete__btn span,
  .group__edit__btn span{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid rgba(19, 49, 89, 0.35);
    display: block;
    line-height: 27px;
  
  }
  .group__delete__btn span{
    border: 1px solid rgba(255, 88, 88, 0.6);
  }



  /* ==============Responsive css============== */
@media only screen and (min-width: 1024px) and (max-width: 1366px)  {
    .btn__wrapper button .btn_text{
      display: none;
    }
    .btn__wrapper .btn_text{
      display: none;
    }
    .button_icon{
      margin-right: unset;
    }
    /*.btn__wrapper a{*/
    /*  background: unset;*/
    /*  margin-right: unset;*/
    /*}*/
    /*.btn__wrapper button{*/
    /*  border-radius: 50%;*/
    /*  padding: 2px 10px;*/
    /*  background: unset;*/
    /*  margin-right: unset;*/
    /*}*/
    .btn__wrapper .zapier-btn{
      padding: 14px 35px;
    }
    .btn__wrapper.alt{
      margin-right: 25px;
    }
    .massaging_service{
      width: 60% !important;
    }
    .btn__wrapper{
      width: 13%;
    }
    .btn__wrapper.alt{
      width: 32%;
    }
    .list__subtitle{
      width: 27%;
    }
    .list__title{
      width: 30%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .btn__wrapper{
    width: 20%;
  }
  .list__title{
    width: 20%;
  }
  .massaging_service{
    width: 60% !important;
  }
  .btn__wrapper button .btn_text{
    display: none;
  }
  .button_icon{
    margin-right: unset;
  }
  .btn__wrapper .btn_text{
    display: none;
  }
  /*.btn__wrapper a{*/
  /*  background: unset;*/
  /*  margin-right: unset;*/
  /*}*/
  /*.btn__wrapper button{*/
  /*  border-radius: 50%;*/
  /*  padding: 6px 9px;*/
  /*  background: unset;*/
  /*  margin-right: unset;*/
  /*}*/
  .btn__wrapper .zapier-btn{
    font-size: 12px;
    width: 180px;
    padding: 3px 22px;
  }
  .btn__wrapper.alt{
    margin-right: 0px;
    width: 30%;
  }
  .lead_flow_top_content h1{
    font-size: 16px;
  }
  .list__subtitle{
    font-size: 12px;
    line-height: 20px;
    width: 25%;
    /* width: 130px; */
  }
  .create_new_leadflow_btn a{
    font-size: 11px;
    padding: 11px 10px;
  }
  .add_rule_btn a{
    padding: 5px 33px !important;
  }
  .title{
    font-size: 12px;
  }
  .lead_flow_list{
    padding: 36px 10px;
  }
  .list__title span,
  .list__title{
    font-size: 10px;
  }
  .add_leadflow_modal{
    width: 60%;
  }
  .sub_title h2{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .lead_flow_top_content{
    padding: 15px 10px !important;
  }
  .top_lead_setting_btn{
    gap: 0px;
    margin-left: -30px;
  }
  .lead_flow_setting_btn{
    padding: 10px 5px;
  }
  .btn_icon{
    margin-right: 7px;
  }
  .list__title span{
    width: 245px;
  }
  /* .list__subtitle{
    margin-left: -50px;
  } */
  .assing_btns a, .assing_btns button{
    font-size: 12px;
  }
  .assing_btns_text{
    font-size: 12px;
  }
  .drp_select input, .rule_inputs input{
    font-size: 12px !important;
  }
  .rule_select{
    width: 80px;
  }
  .rule_input{
    width: 118px;
  }
  .rule_input_search{
    width: 144px;
  }
  .rule_btm_title{
    font-size: 12px !important;
  }
  .rule_inputs{
    gap: 20px;
  }
  .delete_btn button{
    font-size: 12px;
    padding: 8px 18px;
  }
}




.srv-validation-message {
    color: #ff264a;
}
.massaging_service {
    max-width: 1170px;
    width: 100%;
    background: var(--gray_light);
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    left: unset;
    top: 80px !important;
    right: 0 !important;
    position: fixed;
    max-height: 80%;
}
.massaging_service_title {
    border-bottom: 2px solid var(--white);
}
.massage_send_close_button {
    padding: 20px;
}
.modal__search__box {
    background-color: #F6F7FB;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #E5E8EF;
    position: relative;
    width: 100%;
}


/* ============Issue Fix CSS=============== */
.pp_select{
  margin-right: 12px;
}
.rule_input_search .MuiInput-underline:before,
.pp_select .MuiInput-underline:before{
  display: none;
}
.rule_input_search .MuiInput-underline:after,
.pp_select .MuiInput-underline:after{
  display: none;
}
.rule_input_search .MuiSelect-select.MuiSelect-select,
.pp_select .MuiSelect-select.MuiSelect-select{
  background: #E5E8EF;
  border: 1px solid #ADB7C5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px 45px 9px 11px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.65);
}
.rule_input_search input{
  margin-top: 9px !important;
}
.massaging_service:focus-visible{
  outline: none !important;
}
.single_contact_source {
    min-height: 150px;
    display: block;
    border: none;
    border-radius: 5px;
    background: none;
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
}
.single_contact_source .contact_source_img{
    width: 100%;
    height: 100%;
}


.single_contact_source.active {
    border: 2px solid #2f80ed;
}
.single_contact_source:focus {
    background: none;
}
.lead_flow_lists__wrapper .jss1 {
    border: none !important;
}

.list__title {
    word-break: break-word;
}

/* Api Link css start*/
.api_link {
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 0px 10px;
    max-width: 800px;
    left: unset;
    top: 80px !important;
}
.api_link.modal{
    max-height: 88% !important;
}
.api_link_top_content {
    border-bottom: 2px solid var(--gray_dark);
}

.http_form_group {
    padding: 20px;
}

.http_form_group .form-group label {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--dark_blue);
}

.http_form_group .form-group label p {
    display: inline;
    color: var(--red);
}

.http_form_group .form-group label span {
    display: inline;
    font-size: 13px;
    text-transform: none;
    border-bottom: unset;
    color: #8896A8;
}

.http_form_group .form-group input {
    background: #F6F7FB;
    border-radius: 5px;
    max-width: 740px;
    border-bottom: unset !important;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--light_blue);
    padding: 0 13px;
}

.http_form_group .form-group input::placeholder {
    color: var(--light_blue) !important;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    font-weight: 600;
}

.http_form_group .form-group input:focus {
    box-shadow: unset !important;
}

.http_form_group a span {
    width: 20px;
    height: 20px;
    background: var(--white);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-left: -28px;
    margin-top: 43px;
}
.authorization_form_group a span{
    margin-top: 85px !important;
}
.http_form_group .form-group span {
    display: block;
    border-bottom: 1px solid var(--gray_dark);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--dark_blue);
    margin-bottom: 15px;
    text-transform: uppercase;
}

.query_parameters {
    padding: 0 20px;
}

.query_parameters h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--dark_blue);
    border-bottom: 1px solid var(--gray_dark);
    padding-bottom: 10px;
}

.number_text h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--dark_blue);
    margin: 0;
}

.number_text {
    border-bottom: .5px solid #E5E8EF;
    padding-top: 10px;
    padding-bottom: 10px;
}
.number_text:last-child{
    border-bottom: unset !important;
}
.number_text h4 p {
    display: inline;
    color: var(--red);
}

.number_text h4 span {
    display: inline;
    font-size: 13px;
    text-transform: none;
    border-bottom: unset;
    color: #8896A8;
    margin-left: 4px;
}

.number_text p {
    color: #8896A8;
    font-size: 14px;
    margin: 0;
}
.option_3 .input-field .select-wrapper .caret{
    z-index: 1;
    fill: var(--text-color);
}
.back_button_to_account a {
    padding: 8px 15px;
    background: #3c7ef3;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    letter-spacing: .01em;
    color: #fff;
    margin-bottom: 15px;
}
/* Api Link css end*/
.lead_flow_table .lead_flow_lists__wrapper {
	overflow-y: auto;
} 

.lead_flow_table .lead_flow_lists__wrapper.awesome__scroll_bar::scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
	background: rgba(19, 49, 89, 0.5);
} 

.lead_flow_table .lead_flow_lists__wrapper.awesome__scroll_bar::scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
	border-radius: 10px;
	background-color: var(--white);
}